<template>
  <div
    :id="eChartId"
    class="echart-container"
    style="width:100%;height:100%"
  ></div>
</template>

<script>
import * as echarts from "echarts";
import { guid } from "../utils";
export default {
  name: "PieEchart",
  props: {
    seriesName: String,
    color: Array,
    title: Object,
    legend: Object, //图例配置项
    series: Array, //数据配置数组
    radius: Array, //饼图半径大小
    center: Array, //饼图在容器中的位置
    labelShow: {
      //圆圈中间文字
      type: Boolean,
      default: false,
    }, //是否显示标签文本
    labelPosition: String, //文本位置
    labelLineShow: {
      //是否显示视觉引导线
      type: Boolean,
      default: false,
    },
    legShow: {
      //是否显示图例
      type: Boolean,
      default: false,
    },
    seriesData: Array, //绘制数据
    legSty: Object,
    legData: Array,
    legTop: String,
    legLeft: String,
    legRight: String,
    legBottom: String,
    titleText: String,
    subText: String,
    titleTop: String,
    titleLeft: String,
    labelFormatter: String,
    legOrient: String, //图例布局方向
    formatterNum: [Number, String],
    toolFormatter: String,
    length2: Number,
    length: Number,
    titleShow: {
      type: Boolean,
      default: false,
    },
    labelFontSize: {
      type: Number,
      default: 17,
    },
    startAngle: {
      type: Number,
      default: 90,
    },
    top: [Number, String],
    seriesItemLabel: Object,
    otherOptions: Object,
  },
  data() {
    return {
      eChartId: guid(),
      myChart: null,
      defaultColor: [
        "#0FC9D1",
        "#EAC056",
        "#EE8C40",
        "#A96EF3",
        "#427EFF",
        "#749f83",
        "#ca8622",
        "#bda29a",
        "#6e7074",
        "#546570",
        "#c4ccd3",
      ],
    };
  },
  created() {},
  mounted() {
    this.drawPie();
    this.setOptions();
    setTimeout(() => {
      this.myChart.resize();
    }, 0);
    window.addEventListener("resize", this.windowSizeChange);
  },
  watch: {
    seriesData() {
      this.setOptions();
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.windowSizeChange);
  },
  methods: {
    windowSizeChange() {
      this.myChart.resize();
    },
    drawPie() {
      this.myChart = echarts.init(document.getElementById(this.eChartId));
    },
    setOptions() {
      let myOptions = {
        // backgroundColor:"red",//背景色
        title: {
          show: this.titleShow,
          text: this.titleText ? this.titleText : "",
          subtext: this.subText,
          top: this.titleTop || "center",
          left: this.titleLeft || "center",
          textAlign: "center",
          textStyle: {
            color: "#000",
          },
          subtextStyle: {
            color: "#000",
            fontSize: 16,
          },
        },
        color: this.color ? this.color : this.defaultColor,
        tooltip: {
          //悬浮数据
          trigger: "item",
          show: true,
          borderColor: "#0174B0",
          backgroundColor: "rgba(6,12,65,0.8)",
          formatter: this.toolFormatter, //"{a}：<br/>{b}: {c}人"
          textStyle: {
            color: "#fff",
          },
        },
        legend: this.legend
          ? this.legend
          : {
              show: this.legShow,
              orient: this.legOrient ? this.legOrient : "vertical", //图例布局朝向'vertical'horizontal'
              top: this.legTop ? this.legTop : "auto", //距离顶部
              left: this.legLeft ? this.legLeft : "auto", //图例组件距离容器左侧得距离
              right: this.legRight ? this.legRight : "auto",
              bottom: this.legBottom ? this.legBottom : "auto",
              icon: "rect", //图例索引icon
              selectedMode: false, //图例选择模式
              // itemWidth: options.legW, //图例标记得图形宽度
              // itemHeight: options.legH, //图例标记得图形高度
              itemGap: 10, //图例每项之间得间隔
              borderRadius: 6, //圆角半径
              data: this.legData ? this.legData : [], //图例得数据数组
              textStyle: this.legSty
                ? this.legSty
                : {
                    color: "#000",
                    fontSize: 16,
                  },
            },
        series: this.series
          ? this.series
          : [
              {
                name: this.seriesName || "",
                type: "pie",
                radius: this.radius ? this.radius : ["40%", "60%"], //圆环半径大小
                center: this.center ? this.center : ["50%", "50%"], //饼图的中心
                avoidLabelOverlap: true, //是否防重叠
                startAngle: this.startAngle,
                top: this.top,
                // left:"center",
                label: {
                  //图形上得文本标签
                  fontSize: this.labelFontSize,
                  normal: {
                    show: this.labelShow,
                    position: this.labelPosition
                      ? this.labelPosition
                      : "center", //扇形区内部
                    fontSize: this.labelFontSize,
                    fontWeight: "bold",
                    formatter: this.labelFormatter || "{c}",
                    // function(param) {
                    //   if (!param.percent) return "";
                    //   var f = Math.round(param.percent * 10) / 10;
                    //   var s = f.toString();
                    //   var rs = s.indexOf(".");
                    //   if (rs < 0) {
                    //     rs = s.length;
                    //     s += ".";
                    //   }
                    //   while (s.length <= rs + 1) {
                    //     s += "0";
                    //   }
                    //   return s + "%";
                    // }
                    ...this.seriesItemLabel,
                  },
                },
                labelLine: {
                  //是否显示视觉引导线

                  normal: {
                    show: this.labelLineShow,
                    length2: this.length2 || 15,
                    length: this.length || 15,
                    // lineStyle: {
                    //   color: "#000"
                    // }
                  },
                },
                itemStyle: {
                  normal: {
                    borderWidth: 0,
                    borderColor: "0",
                  },
                },
                data: this.seriesData ? this.seriesData : [],
                ...this.otherOptions,
              },
            ],
      };
      this.myChart.setOption(myOptions, true);
    },
  },
};
</script>

<style scoped></style>
