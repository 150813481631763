<template lang="pug">
    div.box-w(:style='{height:h,width:w}' :class='className')
      .ari.ari1
      .ari.ari2
      <slot></slot>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    h: {
      type: [Number, String],
      default: "",
    },
    w: {
      type: [Number, String],
      default: "100%",
    },
  },
  data() {
    return {};
  },
  computed: {
    boxStyle() {
      return {
        height: this.h.is,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.box-w::after,
.box-w::before {
  position: absolute;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background: url("../assets/icon/border-lineheht.png") 0 0 no-repeat;
  background-size: 100% 100%;
}
.box-w::after {
  top: 0;
}
.box-w::before {
  bottom: 0;
}
.box-w {
  position: relative;
  min-height: 44px;
  height: 100%;
  width: 100%;
  background: rgba(28, 42, 117, 0.5);
  box-shadow: inset 0px 0px 5px 3px #071b5a;
  // background: url("../assets/image/bor1.png");
  // background-size: 100% 100%;
  padding: 10px;
  color: #fff;
  .ari {
    height: 26px;
    width: 26px;
    position: absolute;
  }
  .ari1 {
    background: url("../assets/icon/rtarrow.png") 0 0 no-repeat;
    background-size: 100%;
    top: -3px;
    right: -3px;
  }
  .ari2 {
    background: url("../assets/icon/ldarrow.png") 0 0 no-repeat;
    background-size: 100%;
    bottom: -3px;
    left: -3px;
  }
}
</style>
