<template lang="pug">
  .my-table(:class='className' :style='{height:th}')
    .thead
      .th(v-for='item in columns') {{item.label}}
    GeminiScrollbar(class="tbody" autoshow style="height:96%")
      .tr(v-for="(i,index) in tableData"  class="animated flipInX")
        .td(v-for='item2 in columns')
          span {{i[item2.prop]|moneyFormat}}   
      div(style="height:20px")    
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    th: String,
    columns: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    //�Ƿ�����ݶ��ŷָ�����
    split: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.my-table {
  height: 100%;
  width: 100%;
  color: #4c94e8;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  .thead,
  .tr {
    padding: 7px 0;
    display: flex;
  }
  .thead {
    background: rgba(18, 36, 109, 0.4);
    .th {
      flex: 1;
      font-size: 14px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
  .tr {
    display: flex;
    .td {
      flex: 1;
    }
  }
  .tr:nth-child(2n) {
    background: rgba(18, 36, 109, 0.4);
  }
  .tr:hover {
    background: rgba(14, 47, 137, 0.6);
  }
}
</style>
