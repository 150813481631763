<template lang="pug">
  .my-modal-box(v-if='dialogVisible' :class='className')
    elDialog(:visible.sync="dialogVisible"  width="50%" :modal="modal" @close="onClose")
      Box1(h="35rem")
        span.m-title {{title}}
        .my-modal-body
          slot()
</template>

<script>
import Box1 from "./Box1.vue";
export default {
  name: "MyModal",
  components: { Box1 },
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
    modalAct: {
      type: Boolean,
      default: true,
    },
    title: String,
    className: {
      type: String,
      default: "",
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modalShow(newVal) {
      this.dialogVisible = newVal;
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {},
  methods: {
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped lang="less">
.m-title {
  color: #70aaff;
  position: absolute;
  left: 50%;
  top: 15px;
  transform: translateX(-50%);
  font-size: 20px;
}
.my-modal-body {
  height: 100%;
  padding: 60px 10px 10px 10px;
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__headerbtn {
  position: absolute;
  top: 10%;
  background: rgba(9, 26, 85, 0.6);
  border-radius: 50%;
  color: #fff;
  padding: 6px;
  z-index: 6;
  border: 1px solid #2179d6;
  i {
    color: #2179d6;
    font-size: 20px;
  }
}
/deep/ .el-dialog {
  background: transparent;
}
</style>
