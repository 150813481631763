<template>
  <div></div>
</template>

<script>
import * as echarts from "echarts";
import { mapState, mapActions } from "vuex";
const cityCodes = [
  330100,
  330500,
  330400,
  330200,
  331000,
  330900,
  330600,
  330700,
  330300,
  331100,
  330800,
];
export default {
  props: {
    cid: {
      //容器id
      type: String,
      required: true,
    },
    dataList: {
      type: Array,
      default: () => [],
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    cityNumber: {
      type: Number,
      default: 330000,
    },
    cityFlagBack: {
      type: Number,
      default: 0,
    },
    tooltipFormatterType: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      myChart: null,
      cityFlag: true,
    };
  },
  watch: {
    cityFlagBack(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.cityFlag = true;
      }
    },
    dataList(newVal, oldVal) {
      this.registerMap();
      this.setOptions();
      this.mapEvents();
    },
    cityNumber(newVal, oldVal) {
      this.registerMap();
      this.setOptions();
      this.mapEvents();
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.app.userInfo,
    }),
  },
  created() {
    if (this.cityNumber == this.userInfo.districtCode) {
      this.cityFlag = false;
    }
  },
  mounted() {
    this.drawMap();
    this.registerMap();
    this.setOptions();
    setTimeout(() => {
      this.myChart.resize();
    }, 0);
    window.addEventListener("resize", this.windowSizeChange);
    this.mapEvents();
  },
  destroyed() {
    window.removeEventListener("resize", this.windowSizeChange);
  },
  methods: {
    windowSizeChange() {
      this.myChart.resize();
    },
    drawMap() {
      this.myChart = echarts.init(document.getElementById(this.cid));
    },
    registerMap() {
      echarts.registerMap(
        "zhejiang",
        require(`/public/geo/${this.cityNumber}.json`)
      );
    },
    setOptions() {
      let myOptions = {
        tooltip: {
          borderColor: "#0174B0",
          backgroundColor: "rgba(6,12,65,0.8)",
          formatter:
            this.tooltipFormatterType === "1"
              ? function(params) {
                  if (!params.data) return;
                  const {
                    totalCaseCounts,
                    totalClaimPeopleCounts,
                    indemnityTotalPremium,
                    averageFinishCaseTime,
                    simpleIndemnityRate,
                    finishCaseRate,
                  } = params.data;
                  return `
              <div style="backgroud:red">
                <div style="display:flex;"><span style="width:120px;text-align:right">理赔案件总数：</span><span style="color:#F5EF41">${totalCaseCounts}</span></div>
                <div style="display:flex;"><span style="width:120px;text-align:right">理赔总人次：</span><span style="color:#F5EF41">${totalClaimPeopleCounts}</span></div>
                <div style="display:flex;"><span style="width:120px;text-align:right">已赔款总金额：</span><span style="color:#F5EF41">${indemnityTotalPremium}</span></div>
                <div style="display:flex;"><span style="width:120px;text-align:right">平均结案时长：</span><span style="color:#F5EF41">${averageFinishCaseTime}</span></div>
                <div style="display:flex;"><span style="width:120px;text-align:right">简单赔付率：</span><span style="color:#F5EF41">${simpleIndemnityRate} %</span></div>
                <div style="display:flex;"><span style="width:120px;text-align:right">结案率：</span><span style="color:#F5EF41">${finishCaseRate} %</span></div>
              </div>
            `;
                }
              : function(params) {
                  if (!params.data) return;
                  const {
                    totalPremium = 0,
                    totalPeopleCounts = 0,
                    unifyPremium = 0,
                    disunityPremium = 0,
                    unityPeopleCounts = 0,
                    disunityPeopleCounts = 0,
                  } = params.data;
                  return `
              <div style="backgroud:red">
                <div style="display:flex;"><span style="width:120px;text-align:right">总保费(元)：</span><span style="color:#F5EF41">${totalPremium}</span></div>
                <div style="display:flex;"><span style="width:120px;text-align:right">已续转保费(元)：</span><span style="color:#F5EF41">${unifyPremium}</span></div>
                <div style="display:flex;"><span style="width:120px;text-align:right">待续转保费(元)：</span><span style="color:#F5EF41">${disunityPremium}</span></div>
                <div style="display:flex;"><span style="width:120px;text-align:right">总参保人数：</span><span style="color:#F5EF41">${totalPeopleCounts}</span></div>
                <div style="display:flex;"><span style="width:120px;text-align:right">已续转人数：</span><span style="color:#F5EF41">${unityPeopleCounts}</span></div>
                <div style="display:flex;"><span style="width:120px;text-align:right">待续转人数：</span><span style="color:#F5EF41">${disunityPeopleCounts}</span></div>
              </div>
            `;
                },
          textStyle: {
            color: "#fff",
          },
        },
        toolbox: {
          show: false,
          //orient: 'vertical',
          left: "left",
          top: "top",
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        geo: {
          map: "zhejiang",
          aspectScale: 0.85, //长宽比
          roam: false,
          label: {
            show: false,
          },
          silent: true, //图形是否不响应和触发鼠标事件
          itemStyle: {
            color: "#fff",
            normal: {
              areaColor: "#013C62",
              shadowColor: "#00D0FF", //阴影边框颜色
              shadowOffsetX: 0,
              shadowOffsetY: 10,
            },
            emphasis: {
              areaColor: "#2AB8FF",
              borderWidth: 0,
              color: "green",
              label: {
                show: true,
              },
            },
          },
        },
        series: [
          {
            type: "map",
            roam: false,
            map: "zhejiang",
            aspectScale: 0.85,
            selectedMode: false,
            emphasis: {
              label: {
                show: true,
              },
            },
            label: {
              show: true,
              color: "#4798DD",
              fontSize: 13,
              // ["拱墅区 330105", "上城区 330102", "西湖区 330106", "滨江区 330108"],
              formatter: (params) => {
                const { value, name } = params;
                if (value === 330105 && this.cityFlag) {
                  return "1";
                }
                if (value === 330102 && this.cityFlag) {
                  return "2";
                }
                if (value === 330106 && this.cityFlag) {
                  return "3";
                }
                if (value === 330108 && this.cityFlag) {
                  return "4";
                }
                return name;
              },
            },
            itemStyle: {
              normal: {
                borderColor: "#0089D1", //地图边界颜色
                borderWidth: 2,
                shadowColor: "rgba(0,105,181, 0.5)",
                shadowBlur: 10,
                shadowOffsetX: 5,
                shadowOffsetY: 5,
                areaColor: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#00458D", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#002B71", // 100% 处的颜色
                    },
                  ],
                  globalCoord: true, // 缺省为 false
                }, //区域面颜色
              },

              emphasis: {
                areaColor: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#00D6FF", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#005AB9", // 100% 处的颜色
                    },
                  ],
                }, //鼠标悬浮区域面颜色
                borderWidth: 2,
                label: {
                  color: "#fff",
                },
              },
            },
            data: this.dataList,
          },
        ],
      };
      this.myChart.off("click"); //防止事件重复点击
      this.myChart.setOption(myOptions);
    },
    hoverEFormat(name) {
      switch (name) {
        case "杭州市":
          return 330100;
        case "湖州市":
          return 330500;
        case "嘉兴市":
          return 330400;
        case "宁波市":
          return 330200;
        case "台州市":
          return 331000;
        case "舟山市":
          return 330900;
        case "金华市":
          return 330600;
        case "温州市":
          return 330700;
        case "丽水市":
          return 330300;
        case "衢州市":
          return 331100;
        default:
          return 330800;
      }
    },
    mapEvents() {
      this.myChart.on("mouseover", (e) => {
        let name = e.name;
        this.$emit("mouseover", this.hoverEFormat(name));
      });
      this.myChart.on("mouseout", (e) => {
      });
      this.myChart.on("click", (e) => {
        const { value } = e;
        let isCity = cityCodes.some((i) => i === Number(value));
        if (
          value === 330105 ||
          value === 330102 ||
          value === 330106 ||
          value === 330108
        ) {
          this.cityFlag = false;
        } else {
          this.cityFlag = true;
        }
        let parentCityCode = Number(e.data.parentCode);
        if (isCity) {
          parentCityCode = 330000;
        }
        this.$emit("click", parentCityCode, value, e.data.isCity);
      });
    },
  },
};
</script>

<style></style>
