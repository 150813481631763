<template lang="pug">
  .no-data
    .no-pie(:class="typeEchar")
      .no-text 
        span 暂无数据
</template>

<script>
export default {
  props: {
    typeEchar: {
      type: String,
      default: '',
    }
  }
};
</script>

<style lang="less" scoped>
.no-data {
  width: 100%;
  height: 100%;
  .df;
  .aic;
  .jcc;
  background: transparent;
}
.pieChart {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 20px;
  background: rgba(9,15,52,0.6);
  .no-text {
    width: 100%;
    height: 100%;
    background: #08154d;
    border-radius: 50%;
    .df;
    .aic;
    .jcc;
    span {
      font-size: 14px;
      color: #70aaff;
    }
  }
}
.histogram {
  width: 90%;
  height: 90%;
  background: url("../assets/image/histogram.png") no-repeat;
  background-size: 100%;
  .no-text {
    width: 100%;
    height: 100%;
    .df;
    .aic;
    .jcc;
    span {
      font-size: 14px;
      color: #70aaff;
    }
  }
}
.table {
  width: 90%;
  height: 90%;
  background: url("../assets/image/table.png") no-repeat;
  background-size: 100%;
  .no-text {
    width: 100%;
    height: 100%;
    .df;
    .aic;
    .jcc;
    span {
      font-size: 14px;
      color: #70aaff;
    }
  }
}
</style>
