<template>
  <div></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "BarEchart",
  props: {
    cid: {
      type: String,
      required: true,
    },
    lineType: {
      type: Boolean,
      required: false
    },
    title: Object,
    legend: Object, //图例
    legendData: {
      type: Array,
      default: () => [],
    },
    color: Array,
    grid: Object,
    xAxisType: {
      type: String,
      default: "category",
    },
    yAxisType: {
      type: String,
      default: "value",
    },
    yAxisType2: String,
    xAxisName: String,
    yAxisName: {
      type: String,
      default: "",
    },
    yAxisName2: String,
    xAxisData: Array,
    yAxisData: Array,
    seriesLineName: String,
    seriesLineData: Array,
    seriesBarName: String,
    seriesData: Array,
    seriesBarColor: [String, Object, Array],
    barWidth: Number, //柱子宽度
    legRight: String,
    gTop: [String, Number],
    gLeft: {
      type: [String, Number],
      default: "left",
    },
    gRight: [String, Number],
    gBottom: [String, Number],
    xRotate: Number,
    interval: Number,
    tooltipPos: {
      type: Array,
      default: () => ["50%", "50%"],
    },
    minInterval: Number,
    axisTick: {
      type: Boolean,
      default: false,
    },
    splitLine: {
      type: Boolean,
      default: true,
    },
    axisPointer: Object,
    yaxisLineShow: {
      //是否显示y轴坐标轴线
      type: Boolean,
      default: true,
    },
    yaxisLineShow2: {
      //是否显示y轴坐标轴线
      type: Boolean,
      default: true,
    },
    rightyAxisShow: {
      type: Boolean,
      default: false,
    },
    stack: {
      type: Boolean,
      default: false,
    },
    axisTickShow: {
      type: Boolean,
      default: false,
    },
    XaxisLineShow: {
      //是否显示坐标轴轴线
      type: Boolean,
      default: true,
    },
    yNameTextStyle: Object, //y轴坐标name 样式
  },
  data() {
    return {
      myChart: null,
      defaultColor: [
        "#0FC9D1",
        "#EAC056",
        "#EE8C40",
        "#A96EF3",
        "#427EFF",
        "#749f83",
        "#ca8622",
        "#bda29a",
        "#6e7074",
        "#546570",
        "#c4ccd3",
      ],
    };
  },
  watch: {
    seriesData() {
      this.setOptions();
    },
  },
  mounted() {
    this.drawBar();
    this.setOptions();
    setTimeout(() => {
      this.myChart.resize();
    }, 0);
    window.addEventListener("resize", this.windowSizeChange);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowSizeChange);
  },
  methods: {
    windowSizeChange() {
      this.myChart.resize();
    },
    drawBar() {
      this.myChart = echarts.init(document.getElementById(this.cid));
    },
    setBarColor(index) {
      let newColor = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: "#2beeff",
        },
        {
          offset: 0.5,
          color: "#169fe3",
        },
        {
          offset: 1,
          color: "#004bc6",
        },
      ]);
      if (typeof this.seriesBarColor === "string") {
        return this.seriesBarColor;
      }
      if (
        Object.prototype.toString.call(this.seriesBarColor) ===
          "[object Array]" &&
        this.seriesBarColor.length > 1
      ) {
        if (
          Object.prototype.toString.call(this.seriesBarColor[index]) ===
            "[object Array]" &&
          this.seriesBarColor.length > 1
        ) {
          return new echarts.graphic.LinearGradient(
            0,
            0,
            0,
            1,
            this.seriesBarColor[index]
          );
        }
        if (
          Object.prototype.toString.call(this.seriesBarColor[index]) ===
            "[object Array]" &&
          this.seriesBarColor.length === 1
        ) {
          return new echarts.graphic.LinearGradient(
            0,
            0,
            0,
            1,
            this.seriesBarColor[0]
          );
        }
        return this.seriesBarColor[index];
      }
      return newColor;
    },
    setOptions() {
      let myOptions = {
        title: this.title ? this.title : {},
        textStyle: {
          color: "#54A2F6",
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let parasTip = params[0].axisValue + '<br/>';
            for (let i = 0; i < params.length; i++) {
              if (params[i].componentSubType === 'bar') {
                parasTip += params[i].marker + params[i].seriesName + '：' + params[i].value + '<br/>'
              }
            }
            return parasTip;
          },
          axisPointer: {
            type: "shadow",
          },
          borderWidth: "1", //边框宽度设置1
          borderColor: "#0174B0",
          backgroundColor: "rgba(6,12,65,0.8)",
          textStyle: {
            color: "#fff",
          },
          // position: this.tooltipPos,
        },
        legend: this.legend
          ? this.legend
          : {
              show: false,
              data: this.legendData,
              bottom: "6%",
              icon: "circle",
              selectedMode: true,
            },
        color: this.color ? this.color : this.defaultColor, //颜色
        grid: this.grid
          ? this.grid
          : {
              //直角坐标系内绘图网格
              //show: false, //是否显示网格
              top: this.gTop || "10%",
              left: this.gLeft,
              right: this.gRight || "10%",
              bottom: this.gBottom,
              containLabel: true, //是否包含刻度标签
            },
        xAxis: [
          {
            type: this.xAxisType,
            name: this.xAxisName ? this.xAxisName : "",
            nameGap: 16, //坐标轴名称与轴线之间的距离。
            axisLabel: {
              rotate: this.xRotate || 0,
              interval: this.interval,
              textStyle: {
                fontSize: 12,
              },
            },
            axisLine: {
              //坐标轴轴线相关设置
              show: this.XaxisLineShow,
              lineStyle: {
                color: "#000",
              },
            },
            axisTick: {
              show: this.axisTickShow, //是否显示坐标刻度
            },
            data: this.xAxisData ? this.xAxisData : [],
          },
        ],
        yAxis: [
          {
            type: this.yAxisType,
            name: this.yAxisName,
            nameTextStyle: this.yNameTextStyle,
            minInterval: this.minInterval || 1,
            axisLabel: {
              textStyle: {
                fontSize: 14,
              },
            },
            axisLine: {
              //坐标轴轴线相关设置
              show: this.yaxisLineShow,
              lineStyle: {
                color: "#0A2F80",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#0A2F80"],
              },
            },
            axisTick: {
              show: this.axisTick, //是否显示坐标刻度
            },
            data: this.yAxisData ? this.yAxisData : [],
          },
        ],
        barMaxWidth: 20,
        series: [],
      };

      if (this.legend) {
        myOptions.legend.data = this.legendData;
      }
      for (let i = 0; i < this.legendData.length; i++) {
        myOptions.series.push({
          name: this.legendData[i],
          type: "bar",
          stack: this.stack,
          itemStyle: {
            normal: {
              color: this.setBarColor(i),
            },
          },

          data:
            this.legendData.length > 1 ? this.seriesData[i] : this.seriesData,
        });
        // if (this.lineType) {
        //   myOptions.series.push({
        //     name: this.legendData[i],
        //     type: "line",
        //     symbolSize: 3, // 设定实心点的大小
        //     stack: this.stack,
        //     itemStyle: {
        //       normal: {
        //         color: this.setBarColor(i),
        //       },
        //     },
        //     data: this.legendData.length > 1 ? this.seriesData[i] : this.seriesData,
        //   });
        // }
      }
      this.myChart.setOption(myOptions);
    },
  },
};
</script>

<style scoped></style>
